@use "@wojo/ui/mixins" as *;
@include layer(storyblok) {
    .zendesk-article {
        .list {
            ul {
                padding: 0;
                margin: 0;
            }
        }

        .grid {
            display: grid;
            --grid-columns: 1;
            grid-template-columns: repeat(var(--grid-columns, 1), 1fr);
            column-gap: var(--g-spacing-lg);
            grid-auto-flow: row;

            @include breakpoint-md {
                --grid-columns: var(--layout-columns, 1);
            }
        }

        .article-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: var(--g-spacing-md);
            border-bottom: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
            border-top: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
            cursor: pointer;
            list-style: none;
            font-family: var(--g-font-family-primary);
            color: var(--s-color-text-primary);
            font-size: var(--g-font-size-400);
            line-height: var(--g-line-height-400);
            margin-bottom: calc(var(--g-border-width-xs) * -1);

            .icon {
                margin-top: var(--g-spacing-xs);
                margin-right: var(--g-spacing-md);
            }

            &:first-child {
                border-top: var(--g-border-width-xs) solid
                    var(--s-color-separator-standard);
            }

            .link {
                display: flex;
                flex-direction: column;
                text-decoration: none;
                color: var(--g-color-primary-standard);
            }

            &:hover {
                text-decoration: underline;
            }

            .link-title {
                font-weight: var(--g-font-weight-bold);
                margin-bottom: var(--g-spacing-xxs);
            }

            .category {
                font-weight: var(--g-font-weight-regular);
            }
        }

        .button {
            margin-top: var(--g-spacing-md);
        }
    }
}
